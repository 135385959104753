
<div *ngIf="isLoading" class="row" style="margin-top:150px;">
  <div class="col-5">
  </div>
  <div class="col-2">
    <mat-spinner></mat-spinner>
  </div>
  <div class="col-5">
  </div>
</div>

<div *ngIf="!isLoading" class="container">

  <div class="row">
    <div div class="offset-lg-2 col-lg-8">
      <div class="col-lg-12">
        <h2 *ngIf="!user?.AccountSettings?.IsCompany">Adminvy - {{user?.AccountSettings?.FirstName}} {{user?.AccountSettings?.LastName}}</h2>
        <h2 *ngIf="user?.AccountSettings?.IsCompany">Adminvy - {{user?.AccountSettings?.CompanyName}}</h2>
      </div>

    </div>
  </div>

  <div *ngIf="isAdmin" class="row">
    <div div class="offset-lg-2 col-lg-8">
      <div class="col-lg-12">
        <div *ngIf="user?.AccountSettings.CreatedFromSpar">Data hämtat från SPAR: {{user?.AccountSettings.LastSparUpdate | date:'shortDate'}}</div>
        <div *ngIf="!user?.AccountSettings.CreatedFromSpar">Data ej hämtat från SPAR</div>
      </div>
      <div class="col-lg-12">
        <mat-form-field style="width: 100%" floatPlaceholder="never">
          <input matInput [ngModel]="user?.AccountSettings.AdminInfo" (ngModelChange)="user.AccountSettings.AdminInfo = $event" placeholder="Info om medlem som bara admin ser" id="admininfo" name="admininfp">
        </mat-form-field>
      </div>
      <div class="row col-12" style="height: 67px;">
        <div class="col-lg-6">
          <div style="width: 100%" floatPlaceholder="never">
            <mat-checkbox matInput [ngModel]="user?.AccountSettings.HasCustomServiceFee" (change)="changeValue($event)" id="hascustomservicefee" name="hascustomservicefee">Har avtalad serviceavgift</mat-checkbox>
          </div>
        </div>
        <div class="col-lg-6" *ngIf="user.AccountSettings.HasCustomServiceFee">
          <mat-form-field style="width: 100%" floatPlaceholder="never">
            <input type="number" matInput [ngModel]="CustomServiceFee" (ngModelChange)="CustomServiceFee = $event" placeholder="Avtalad serviceavgift, procent" id="servicefee" name="servicefee" #servicefee="ngModel">
          </mat-form-field>
        </div>
      </div>
      <div class="row col-12">
        <div class="col-lg-6">
          <mat-form-field style="width: 100%" floatPlaceholder="never">
            <mat-select placeholder="Valda mailnotifikationer" [(ngModel)]="selectedNotificationTypes" (ngModelChange)="notificationSettingsChange($event)" [ngModelOptions]="{standalone: true}" multiple>
              <mat-option *ngFor="let s of user.AccountSettings.NotificationSettings" [value]="s.Type.Id">
                {{ s.Type.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <div style="width: 100%" floatPlaceholder="never">
            <mat-checkbox matInput [ngModel]="user?.AccountSettings.Suspended" (change)="changeValueSuspended($event)" id="suspended" name="suspended">Medlemmen är spärrad</mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ********************* -->
  <!-- Private user settings -->
  <!-- ********************* -->

  <div *ngIf="!user?.AccountSettings.IsCompany" class="row">
    <div div class="offset-lg-2 col-lg-8 entry">
      <mat-card>
        <form name="form" (ngSubmit)="onSubmit()" #f="ngForm">

          <div class="row" style="margin-bottom: 15px;">

            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.EmploymentNumber" (ngModelChange)="user.AccountSettings.EmploymentNumber = $event" placeholder="Anställningsnummer" id="employmentnumber" name="employmentnumber">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput readonly [ngModel]="user?.AccountSettings.MemberNumber" (ngModelChange)="user.AccountSettings.MemberNumber = $event" placeholder="Medlemsnummer" id="membernumber" name="membernumber">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.FirstName" (ngModelChange)="user.AccountSettings.FirstName = $event" placeholder="Förnamn" id="firstname" name="firstname">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.LastName" (ngModelChange)="user.AccountSettings.LastName = $event" placeholder="Efternamn" id="lastname" name="lastname">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.DisplayName" (ngModelChange)="user.AccountSettings.DisplayName = $event" placeholder="Valt visningsnamn" id="displayname" name="displayname">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.Phone" (ngModelChange)="user.AccountSettings.Phone = $event" placeholder="Telefon" id="telephone" name="telephone">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.Email" (ngModelChange)="user.AccountSettings.Email = $event" placeholder="E-post" id="email" name="email">
              </mat-form-field>
            </div>

            <!-- Adress -->

            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.Address" (ngModelChange)="user.AccountSettings.Address = $event" placeholder="Folkbokföringsadress" id="address" name="address">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.Co" (ngModelChange)="user.AccountSettings.Co = $event" placeholder="c/o" id="coaddress" name="coaddress">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.Zip" (ngModelChange)="user.AccountSettings.Zip = $event" placeholder="Postnummer" id="zip" name="zip">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.City" (ngModelChange)="user.AccountSettings.City = $event" placeholder="Ort" id="city" name="city">
              </mat-form-field>
            </div>

            <!-- Utdelningsadress -->

            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.DeliveryAddress" (ngModelChange)="user.AccountSettings.DeliveryAddress = $event" placeholder="Utdelningsadress" id="levaddress" name="levaddress">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.DeliveryZip" (ngModelChange)="user.AccountSettings.DeliveryZip = $event" placeholder="Postnummer" id="levzip" name="levzip">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.DeliveryCity" (ngModelChange)="user.AccountSettings.DeliveryCity = $event" placeholder="Ort" id="levcity" name="levcity" #levcity="ngModel">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field>
                <mat-select name="companies" placeholder="Kommun" [(ngModel)]="user.AccountSettings.Municipality.Id" (ngModelChange)="municipalityChange($event)">
                  <mat-option *ngFor="let c of municipalities" [value]="c.Id">
                    {{ c.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span style="margin-left:25px;">Skatt: {{user.AccountSettings.Municipality.Tax * 100 | number:'1.2-2'}} %</span>
            </div>
            <div class="col-sm-12 col-md-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.PersonalNumber" (ngModelChange)="user.AccountSettings.PersonalNumber = $event" placeholder="Personnummer" id="PersonalNumber" name="PersonalNumber">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-checkbox matInput [ngModel]="user?.AccountSettings.HasCoordinationNumber" ngModel (change)="changeCoordinationNumberValue($event)" id="hascono" name="hascono">Medlemmen har samordningsnummer</mat-checkbox>
            </div>
            <div *ngIf="user.AccountSettings.HasCoordinationNumber" class="col-sm-6">
            </div>
            <div *ngIf="user.AccountSettings.HasCoordinationNumber" class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never" ngModel>
                <input matInput [ngModel]="user?.AccountSettings.BirthYear" (ngModelChange)="user.AccountSettings.BirthYear = $event" ngModel pattern="[0-9]{8}" maxlength="8" placeholder="ÅÅÅÅMMDD" id="BirthYear" name="BirthYear">
                <mat-hint>Födelsedatum (8 siffror)</mat-hint>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.DiscountCode" (ngModelChange)="user.AccountSettings.DiscountCode = $event" placeholder="Kampanjkod" id="discountcode" name="discountcode">
              </mat-form-field>
            </div>
          </div>

          <!--Tjänstepension-->
          <div class="row" style="margin-bottom: 20px;">
            <div class="col-12">
              <h3>Avsättning till tjänstepension</h3>
            </div>

            <div class="col-lg-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput
                       [ngModel]="user?.AccountSettings?.PensionSavingsSettings?.Amount"
                       (ngModelChange)="user.AccountSettings.PensionSavingsSettings.Amount = $event"
                       placeholder="Andel"
                       type="number"
                       id="PensionAmount"
                       name="PensionAmount">
                <!--<input matInput required [ngModel]="user?.AccountSettings?.PensionSavingsSettings?.Amount" (ngModelChange)="user?.AccountSettings?.PensionSavingsSettings?.Amount = $event" placeholder="Procent eller kronor" id="PensionAmount" name="PensionAmount">-->
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field style="width: 100%;">
                <select [ngModel]="user?.AccountSettings?.PensionSavingsSettings?.Unit?.Id"
                        (ngModelChange)="pensionSavingsTypeChanged($event)"
                        matNativeControl
                        [ngModelOptions]="{standalone: true}">
                  <option value="1">Procent</option>
                  <option value="2">SEK</option>
                </select>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <div>Val av tjänstepensionsföretag:</div>
              <mat-form-field style="width: 100%;">
                <select [ngModel]="user?.AccountSettings?.PensionSavingsSettings?.PensionSavingsCompany?.Id"
                        (ngModelChange)="pensionSavingsCompanyChanged($event)"
                        matNativeControl
                        [ngModelOptions]="{standalone: true}">
                  <option value="1">SPP</option>
                  <option value="2">Avanza</option>
                  <option value="3">Inget valt</option>
                </select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" style="margin-bottom: 15px;">

            <!-- BANK -->
                <div class="col-12">
                  <h4>
                    Bankinformation
                  </h4>
                </div>
                <div class="col-12">

                  <mat-form-field floatPlaceholder="never" style="width: 100%;">
                    <mat-label>Bank</mat-label>
                    <mat-select [(ngModel)]="user.AccountSettings.Bank.Id"
                                name="bank"
                                pattern="^[1-9][0-9]?$"
                                (ngModelChange)="bankOnChange($event)">
                      <mat-option *ngFor="let b of banks" [value]="b.Id">
                        {{ b.Name }}
                      </mat-option>
                    </mat-select>
                    <mat-error>Välj bank</mat-error>
                  </mat-form-field>

                </div>
              </div>

              <div class="row" *ngIf="user?.AccountSettings.Bank.IsInternational" style="margin-bottom: 15px;">

                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput
                           [ngModel]="user?.AccountSettings.Bic"
                           (ngModelChange)="user.AccountSettings.Bic = $event"
                           placeholder="BIC"
                           id="bic"
                           pattern="^(?:[a-zA-Z0-9]{8}|[a-zA-Z0-9]{12})$"
                           name="bic">
                    <mat-error>Ange 8 eller 12 bokstäver och siffror</mat-error>
                    <mat-hint>Ange 8 eller 12 bokstäver och siffror</mat-hint>
                  </mat-form-field>
                </div>

                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput
                           [ngModel]="user?.AccountSettings.Iban"
                           (ngModelChange)="user.AccountSettings.Iban = $event"
                           placeholder="IBAN"
                           id="iban"
                           pattern="[a-zA-Z0-9]{0,34}"
                           name="iban">
                    <mat-error>Ange upp till 34 bokstäver och siffror</mat-error>
                    <mat-hint>Ange upp till 34 bokstäver och siffror</mat-hint>
                  </mat-form-field>
                </div>

              </div>

              <div class="row" *ngIf="user?.AccountSettings?.Bank?.Id == 0 || !user?.AccountSettings.Bank.IsInternational" style="margin-bottom: 15px;">

                <div class="col-sm-6" *ngIf="user?.AccountSettings?.Bank?.Id == 0 || user?.AccountSettings.Bank.ClearingRequired">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.ClearingNumber"
                           (ngModelChange)="user.AccountSettings.ClearingNumber = $event"
                           placeholder="Clearingnummer"
                           id="ClearingNumber"
                           name="ClearingNumber"
                           pattern="{{user?.AccountSettings.Bank.ClearingNumberValidationRegex}}">
                    <mat-error>
                      {{user?.AccountSettings.Bank.ClearingNumberValidationErrorText}}
                    </mat-error>
                    <mat-hint>
                      {{user?.AccountSettings.Bank.ClearingNumberValidationErrorText}}
                    </mat-hint>
                  </mat-form-field>
                </div>

                <div class="col-sm-6" *ngIf="user?.AccountSettings?.Bank?.Id == 0 || user.AccountSettings.Bank.Id > 0">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.AccountNumber"
                           (ngModelChange)="user.AccountSettings.AccountNumber = $event"
                           placeholder="Kontonummer"
                           id="AccountNumber"
                           name="AccountNumber"
                           pattern="{{user?.AccountSettings.Bank.AccountNumberValidationRegex}}">
                    <mat-error>
                      {{user?.AccountSettings.Bank.AccountNumberValidationErrorText}}
                    </mat-error>
                    <mat-hint>
                      {{user?.AccountSettings.Bank.AccountNumberValidationErrorText}}
                    </mat-hint>
                  </mat-form-field>
                </div>

            <!-- /BANK -->

            <!--<div class="col-sm-12 col-md-4">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.BankName" (ngModelChange)="user.AccountSettings.BankName = $event" placeholder="Banknamn" id="BankName" name="BankName">
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-4">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.ClearingNumber" (ngModelChange)="user.AccountSettings.ClearingNumber = $event" placeholder="Clearingnummer" id="ClearingNumber" name="ClearingNumber">
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-4">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.AccountNumber" (ngModelChange)="user.AccountSettings.AccountNumber = $event" placeholder="Kontonummer" id="AccountNumber" name="AccountNumber">
                </mat-form-field>
              </div>-->
            <div class="col-sm-12" style="margin-bottom:15px;font-size: 16px;">
              <span>Medlem sedan: {{user?.AccountSettings.Created | date: 'yyyy-MM-dd' }}</span>
            </div>

            <div class="col-sm-12 col-md-6" style="margin-bottom:15px;">
              <div *ngIf="!f.valid" style="color:red;">Ett eller fler obligatoriska fält saknar innehåll!</div>
            </div>
          </div>

          <!-- Skattejämkningar -->
          <div class="row">

            <div class="col-6">
              <h3>Skattejämkning eller ändring av skatt</h3>
            </div>
            <div class="col-6">
              <!--Knapp för skattejämkning-->
              <a class="col-12" mat-stroked-button (click)="addTaxAdjustment()" style="margin-top:25px;float:right;"><mat-icon>add</mat-icon> &nbsp; Lägg till skattejämkning/ändra skatt &nbsp;</a>
            </div>

            <!--Visar tabell för skattejämkningar-->
            <div class="col-12" *ngIf="user?.AccountSettings.TaxAdjustments.length >0">
              <mat-table [dataSource]="dataSourceTaxAdjustments" style="margin-top: 15px;">

                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef class="col-4">Typ</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="col-4">{{ row?.TaxAdjustmentType?.Name }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="startdate">
                  <mat-header-cell *matHeaderCellDef>Startdatum</mat-header-cell>
                  <mat-cell *matCellDef="let row">{{ row?.DateStart | date: 'yyyy-MM-dd' }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="enddate">
                  <mat-header-cell *matHeaderCellDef>Slutdatum</mat-header-cell>
                  <mat-cell *matCellDef="let row">{{ row?.DateEnd | date: 'yyyy-MM-dd' }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="maxamount">
                  <mat-header-cell *matHeaderCellDef>Maxsumma</mat-header-cell>
                  <mat-cell *matCellDef="let row">{{ row?.MaxAmount | number : '1.2-2'  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="temptax">
                  <mat-header-cell *matHeaderCellDef>Tillfällig skatt</mat-header-cell>
                  <mat-cell *matCellDef="let row">{{ row?.TemporaryTax * 100 | number : '1.2-2'  }} %</mat-cell>
                </ng-container>

                <ng-container matColumnDef="tempemptax">
                  <mat-header-cell *matHeaderCellDef>0 % AGA</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span *ngIf="row?.ZeroEmployerTax">Ja</span>
                    <span *ngIf="!row?.ZeroEmployerTax"></span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="attachment">
                  <mat-header-cell *matHeaderCellDef>Fil</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <a *ngIf="row?.Attachment?.Guid != null" (click)="showFile(row.Attachment)"><mat-icon>open_in_new</mat-icon></a>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="admin">
                  <mat-header-cell *matHeaderCellDef style="border-left: 1px solid rgba(0, 0, 0, 0.12);">Admin</mat-header-cell>
                  <mat-cell *matCellDef="let row" style="border-left: 1px solid rgba(0, 0, 0, 0.12);">
                    <a mat-icon-button (click)="deleteTaxAdjustment(row)" style="color:red;"><mat-icon>delete_forever</mat-icon></a>
                    <a mat-icon-button (click)="editTaxAdjustment(row)" style=""><mat-icon>update</mat-icon></a>

                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsTaxAdjustments"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsTaxAdjustments;">
                </mat-row>
              </mat-table>

            </div>
            <!-- Slut tabell för skattejämkning-->
            <!--<div class="col-sm-12">
              <button mat-stroked-button mat-flat-button color="warn" [disabled]="isLoading || !f.valid" style="float:right; margin-top:20px;">Spara</button>
            </div>-->
          </div>


          <!-- Utmätning av lön -->
          <div class="row" style="margin-top: 20px;">

            <div class="col-6">
              <h3>Utmätning av lön</h3>
            </div>
            <div class="col-6">
              <!--Knapp för att lägga till utmätning-->
              <a class="col-12" mat-stroked-button (click)="addAttachmentOfSalary()" style="margin-top:25px;float:right;"><mat-icon>add</mat-icon> &nbsp; Lägg till utmätning av lön &nbsp;</a>
            </div>

            <!--Visar tabell för utmätning -->
            <div class="col-12" *ngIf="user.AccountSettings.AttachmentOfSalaries.length >0">

              <mat-table [dataSource]="dataSourceAttachmentOfSalary" style="margin-top: 15px;">

                <ng-container matColumnDef="startdate">
                  <mat-header-cell *matHeaderCellDef>Startdatum</mat-header-cell>
                  <mat-cell *matCellDef="let row">{{ row?.DateStart | date: 'yyyy-MM-dd' }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="enddate">
                  <mat-header-cell *matHeaderCellDef>Slutdatum</mat-header-cell>
                  <mat-cell *matCellDef="let row">{{ row?.DateEnd | date: 'yyyy-MM-dd' }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="reservedamount">
                  <mat-header-cell *matHeaderCellDef>Reserverat belopp</mat-header-cell>
                  <mat-cell *matCellDef="let row">{{ row?.ReservedAmount | number : '1.0-0'  }} SEK</mat-cell>
                </ng-container>

                <ng-container matColumnDef="attachmentamount">
                  <mat-header-cell *matHeaderCellDef>Utmätningsbelopp</mat-header-cell>
                  <mat-cell *matCellDef="let row">{{ row?.AttachmentAmount | number : '1.0-0'  }} SEK</mat-cell>
                </ng-container>

                <ng-container matColumnDef="attachment">
                  <mat-header-cell *matHeaderCellDef>Uppladdad fil</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <a *ngIf="row?.Document?.Guid != null" mat-stroked-button color="secondary" (click)="showFile(row.Document)"><mat-icon>open_in_new</mat-icon></a>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="admin">
                  <mat-header-cell *matHeaderCellDef style="border-left: 1px solid rgba(0, 0, 0, 0.12);">Admin</mat-header-cell>
                  <mat-cell *matCellDef="let row" style="border-left: 1px solid rgba(0, 0, 0, 0.12);">
                    <a mat-icon-button (click)="deleteAttachmentOfSalary(row)" style="color:red;"><mat-icon>delete_forever</mat-icon></a>
                    <a mat-icon-button (click)="editAttachmentOfSalary(row)" style=""><mat-icon>update</mat-icon></a>

                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsAttachmentsOfSalary"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsAttachmentsOfSalary;">
                </mat-row>
              </mat-table>

            </div>
            <!-- Slut tabell för utmätning -->

            <div class="col-sm-12">
              <button mat-stroked-button mat-flat-button color="warn" [disabled]="isLoading" style="float:right; margin-top:20px;">Spara</button>
            </div>

          </div>


        </form>
      </mat-card>
    </div>
  </div>

  <!-- ********************* -->
  <!-- Company user settings -->
  <!-- ********************* -->

  <div *ngIf="user?.AccountSettings.IsCompany" class="row">
    <div class="offset-lg-2 col-lg-8 entry">
      <mat-card>
        <br />
        <form name="form" *ngIf="!isLoading" (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
          <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.EmploymentNumber" (ngModelChange)="user.AccountSettings.EmploymentNumber = $event" placeholder="Anställningsnummer" id="employmentnumber" name="employmentnumber">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput readonly [ngModel]="user?.AccountSettings.MemberNumber" (ngModelChange)="user.AccountSettings.MemberNumber = $event" placeholder="Medlemsnummer" id="membernumber" name="membernumber">
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.CompanyName" (ngModelChange)="user.AccountSettings.CompanyName = $event" placeholder="Företagets namn" id="companyname" name="companyname">
              </mat-form-field>
            </div>
            <div class="col-lg-6" *ngIf="user.AccountSettings.Country.Id == null || user.AccountSettings.Country.Name == 'Sverige'">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.OrgNumber" (ngModelChange)="user.AccountSettings.OrgNumber = $event" ngModel placeholder="Organisationsnr" id="organisationsnr" name="Organisationsnr">
              </mat-form-field>
            </div>
            <div class="col-lg-6" *ngIf="user.AccountSettings.Country.Id != null && user.AccountSettings.Country.Name != 'Sverige'">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.Vat" (ngModelChange)="user.AccountSettings.Vat = $event" ngModel placeholder="VAT" id="VAT" name="VAT">
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.Email" (ngModelChange)="user.AccountSettings.Email = $event" placeholder="E-post" id="email" name="email">
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.EmailInbox" (ngModelChange)="user.AccountSettings.EmailInbox = $event" placeholder="E-post för proforma-faktura" id="emailinbox" name="emailinbox">
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.Phone" (ngModelChange)="user.AccountSettings.Phone = $event" placeholder="Telefon" id="telephone" name="telephone">
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-select name="country" placeholder="Land" id="country" [(ngModel)]="user.AccountSettings.Country.Id" (ngModelChange)="user.AccountSettings.Country.Id = $event; getCountryName(user.AccountSettings.Country.Id);" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let c of countries" [value]="c.Id">
                  {{ c.Name }}
                </mat-option>
              </mat-select>
            </div>

            <!-- Fakturadress -->
            <div class="col-lg-12" style="margin: 20px 0">
              <h4>Företagsadress</h4>
            </div>

            <div class="col-lg-12">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.Address" (ngModelChange)="user.AccountSettings.Address = $event" placeholder="Företagsadress (gatuadress)" id="address" name="address">
              </mat-form-field>
            </div>
            <div class="col-lg-12">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.Co" (ngModelChange)="user.AccountSettings.Co = $event" placeholder="Företagsadress 2 (t ex c/o)" id="coaddress" name="coaddress">
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.Zip" (ngModelChange)="user.AccountSettings.Zip = $event" placeholder="Postnummer" id="zip" name="zip">
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.City" (ngModelChange)="user.AccountSettings.City = $event" placeholder="Ort" id="city" name="city">
              </mat-form-field>
            </div>

            <!-- Leveransadress -->

            <div class="col-lg-12" style="margin: 20px 0">
              <h4>Särskild fakturaadress, om annan än ovan</h4>
            </div>

            <div class="col-lg-12">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.DeliveryAddress" (ngModelChange)="user.AccountSettings.DeliveryAddress = $event" placeholder="Fakturaadress (gatuadress)" id="levaddress" name="levaddress">
              </mat-form-field>
            </div>
            <div class="col-lg-12">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.DeliveryCo" (ngModelChange)="user.AccountSettings.DeliveryCo = $event" placeholder="Fakturaadress 2 (t ex c/o)" id="levaddress" name="levaddress2">
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.DeliveryZip" (ngModelChange)="user.AccountSettings.DeliveryZip = $event" placeholder="Postnummer" id="levzip" name="levzip">
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input matInput [ngModel]="user?.AccountSettings.DeliveryCity" (ngModelChange)="user.AccountSettings.DeliveryCity = $event" placeholder="Ort" id="levcity" name="levcity" #levcity="ngModel">
              </mat-form-field>
            </div>



            <!-- Bankuppgifter -->
            <div class="col-lg-12" style="margin: 20px 0">
              <h4>Bankuppgifter</h4>
            </div>

            <div class="row col-lg-12" *ngIf="user.AccountSettings.Country.Id == null || user.AccountSettings.Country.Name == 'Sverige'">
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.BankName" (ngModelChange)="user.AccountSettings.BankName = $event" placeholder="Banknamn" id="BankName" name="BankName">
                </mat-form-field>
              </div>
              <div class="col-lg-3">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.ClearingNumber" (ngModelChange)="user.AccountSettings.ClearingNumber = $event" placeholder="Clearingnummer" id="ClearingNumber" name="ClearingNumber">
                </mat-form-field>
              </div>
              <div class="col-lg-3">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.AccountNumber" (ngModelChange)="user.AccountSettings.AccountNumber = $event" placeholder="Kontonummer" id="AccountNumber" name="AccountNumber">
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.Bankgiro" (ngModelChange)="user.AccountSettings.Bankgiro = $event" placeholder="Bankgiro" id="AccountNumber" name="Bankgiro">
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.Plusgiro" (ngModelChange)="user.AccountSettings.Plusgiro = $event" placeholder="Plusgiro" id="AccountNumber" name="Plusgiro">
                </mat-form-field>
              </div>
            </div>

            <div class="row col-lg-12" *ngIf="user.AccountSettings.Country.Id == null || user.AccountSettings.Country.Name != 'Sverige'">
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.Iban" (ngModelChange)="user.AccountSettings.Iban = $event" placeholder="IBAN" id="iban" name="Iban">
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input matInput [ngModel]="user?.AccountSettings.Bic" (ngModelChange)="user.AccountSettings.Bic = $event" placeholder="BIC" id="bic" name="Bic">
                </mat-form-field>
              </div>
            </div>

            <!-- Övrigt -->
            <div class="col-lg-12" style="margin: 20px 0">
              <h4>Övrigt</h4>
            </div>
            <div class="row col-12">
              <div class="col-lg-6">
                <div style="width: 100%" floatPlaceholder="never">
                  <mat-checkbox matInput [ngModel]="user?.AccountSettings.FTax" (change)="changeFTaxValue($event)" id="ftax" name="ftax">Registrerad för F-skatt</mat-checkbox>
                </div>
              </div>
              <div class="col-lg-6">
                <mat-form-field style="width: 100%" floatPlaceholder="never">
                  <input type="number" matInput [ngModel]="Commission" (ngModelChange)="Commission = $event" placeholder="Provision, procent" id="commission" name="commission" #commission="ngModel">
                </mat-form-field>
              </div>
            </div>


            <!-- Spara -->
            <div class="col-sm-12">
              <button color="accent" mat-flat-button [disabled]="isLoading" style="float:right; margin-top: 20px;">Spara</button>
            </div>

            <div class="col-sm-12">
              <div style="float: right; color: red; font-size: 80%; font-style: italic; padding-top: 10px;">{{validationErrorMessage}}</div>
            </div>
          </div>

        </form>
      </mat-card>
    </div>
  </div>

  <!-- ********************* -->
  <!-- Common settings -->
  <!-- ********************* -->



</div>

