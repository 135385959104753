import { Component, OnInit, ViewChild } from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MatTable, MatTableDataSource } from '@angular/material';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IUser } from '../../core/interfaces/user/IUser';
import { UserService } from '../../core/services/UserService';
import { IInvoice } from '../../core/interfaces/IInvoice';
import { InvoiceService } from '../../core/services/InvoiceService';
import { IEmployerTax } from '../../core/interfaces/IEmployerTax';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { MunicipalityService } from '../../core/services/municipalityService';
import { MessagesDialogComponent } from '../../shared/messages/messages-dialog-component';
import { IGlobalMessage } from '../../core/interfaces/IGlobalMessage';
import { GlobalMessageService } from '../../core/services/GlobalMessageService';
import { environment as env } from '@env/environment';

@Component({
  selector: 'anms-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})

export class AboutComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  releaseButler = require('../../../assets/release-butler.png');
  displayedColumns = ['icon', 'customer', 'paymentdue', 'money'];
  displayedColumnsCompany = ['invoicenumbercomp', 'amountcomp', 'paymentduecomp', 'invoicestatuscomp'];
  public isLoading: boolean = true;
  public user: IUser;
  public employertax: IEmployerTax;
  public municipalitytax: any;
  public invoice: IInvoice;
  public globalmessages: IGlobalMessage;
  public isAdmin: boolean = false;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  invoiceData: Array<any>;
  statuses = [];
  selectedFilterStatus: any = { Id: 0, Name: 'Alla fakturor' };
  filter: string;
  dataSource: MatTableDataSource<IInvoice>;
  prelPaymentDue: Date;
  DueDate: number;
  municipalities: any;
  isRead: boolean;
  isNotRead: boolean;
  public appId: number = env.appId;
  isCompanyAdmin: boolean = false;

  // Calculator
  invoiceFee: number = 29;
  samFee: number = 0.02; // Check if in DataBase
  samFeeExpress: number = 0.05; // Check if in DataBase
  samFeeSlide: boolean = false;
  samFeeNew: number;
  totalInvoiceAmount: number;
  partialTotalAmount: number;
  grossSalary: number;
  netSalary: number;
  employerTax: 0.3142;
  municipalityTax: number;
  displayTax: number;
  FeePaidByReciever: any;
  tempEmployerTax: number = 0.3; // Check if in DataBase
  arbetsgivarAvgift: number;
  myTax: number;
  noVat: number;
  withVat: number;
  vatStandard: number = 1.25; // Check if in DataBase
  vatMedium: number = 1.12; // Check if in DataBase
  vatLow: number = 1.06; // Check if in DataBase
  vat: number;
  withVatMedium: number;
  noVatMedium: number;
  noVatLow: number;
  withVatLow: number;
  totalInvoiceAmountR: number;
  MyNetSalaryR: number;
  MyGrossSalaryR: number;
  PartialTotalAmountR: number;
  samFeeNewR: number;
  netSalaryExpress: number;
  totalInvoiceAmountExpress: number;
  isCompanyUser: boolean = false;
  municipalityTaxMessage: string;

  constructor(
    private invoiceService: InvoiceService,
    private localStorageService: LocalStorageService,
    private loadingService: LoadingBarService,
    private userService: UserService,
    private municipalityService: MunicipalityService,
    private globalmessageService: GlobalMessageService,
    private router: Router,
    public dialog: MatDialog,
  ) { }
    
  ngOnInit() {

    this.loadUser();
    this.loadingService.start();
    this.loadGlobalMessages();

    if (this.appId == 1) {
      this.loadMunicipalities();
    }
  }

  loadUser() {
    this.userService.getCurrentUser()
      .subscribe(value => this.userLoaded(value),
        error => console.log(error)
      );
  }

  async userLoaded(user: IUser) {
    this.user = user;
    this.isAdmin = await this.userService.userHasRole("admin");
    this.isCompanyAdmin = await this.userService.userHasRole('companyadmin');
    
    if (this.user.AccountSettings.IsCompany || this.isCompanyAdmin) {
      this.isCompanyUser = true;
    }
     

    // Default selectted item from storage
    var defaultItem = this.localStorageService.getItem("filterStatusDefault");
    if (defaultItem && this.isAdmin && this.appId == 1) {
      var item = JSON.parse(defaultItem);
      this.selectedFilterStatus = { Id: item.Id, Name: item.Name };

      this.getInvoices([item.Id], 0, 10, this.filter);

    }
    else {
      this.getInvoices(null, 0, 10, this.filter);
    }

    this.getUserEmployerTax();
    this.getUserMunicipalityTax();
    //console.log(this.user);
  }

  getUserEmployerTax() {
    this.userService.getUserEmployerTax(this.user.AccountSettings.Id)
      .subscribe(value => this.employerTaxLoaded(value),
        error => console.log(error)
      );
  }

  employerTaxLoaded(userEmployerTax: any) {
    this.employertax = userEmployerTax;
  }

  getUserMunicipalityTax() {
    var today = new Date();

    this.userService.getUserMunicipalityTax(today, this.user.AccountSettings.Id)
      .subscribe(value => this.municipalityTaxLoaded(value),
        error => console.log(error)
      );
  }

  municipalityTaxLoaded(userMunicipalityTax: any) {
    this.municipalitytax = userMunicipalityTax;

    var municipalityTaxPercent = this.municipalitytax * 100;

    if (this.municipalitytax != this.user.AccountSettings.Municipality.Tax) {
      this.municipalityTaxMessage = "Du har en skattejustering eller -jämkning och vi räknar med skattesatsen " + municipalityTaxPercent + " %.";
    }
    else {

      this.municipalityTaxMessage = "Din kommun är angiven till " + this.user.AccountSettings.Municipality.Name + " (" + this.user.AccountSettings.Municipality.Tax * 100 + " %)."
    }
  }

  getInvoices(statuses: Array<number> = null, pageIndex: number, pageSize: number, filter: string) {

    this.isLoading = true;

    // All invoices selected
    if (statuses != null && statuses.length == 1 && statuses[0] == 0) {
      statuses = null;

    }

    //if (this.isAdmin == true) {
    //  this.invoiceService.getInvoices(statuses, pageIndex,pageSize, filter)
    //    .subscribe(value => this.onInvociesRetrieved(value),
    //      error => console.log(error)
    //    )
    //}

    if (this.appId == 1) {
      this.invoiceService.getInvoicesWithTransLogs(statuses, pageIndex, pageSize, filter)
        .subscribe(value => this.onInvociesRetrieved(value),
          error => console.log(error)
        );
    }

    if (this.appId == 2) {
      this.invoiceService.getInvoices(statuses, pageIndex, pageSize, filter)
        .subscribe(value => this.onInvociesRetrieved(value),
          error => console.log(error)
        );
    }
  }

  onInvociesRetrieved(response: any) {

    for (var i = 0; i < response.Invoices.length; i++) {
      if (response.Invoices[i].Status != undefined)
        response.Invoices[i].Status.Id = response.Invoices[i].Status.Id.toString();
    }

    this.invoiceData = response.Invoices;
    this.dataSource = new MatTableDataSource(this.invoiceData);

    this.isLoading = false;
    this.loadingService.complete();
  }

  // salaryCalculator
  myCalculator(finalAmount: number) {

    //this.samFeeNew = Math.round(this.totalInvoiceAmount * this.samFee); // this.invoice.ServiceFee
    this.samFeeNew = this.calculateServiceFeeAmount(this.totalInvoiceAmount) + 29; //Service fee + invoice fee

    this.partialTotalAmount = Math.round(this.totalInvoiceAmount - this.samFeeNew);

    this.grossSalary = Math.round(this.partialTotalAmount / (1 + this.employertax.Tax));
    this.netSalary = Math.round(this.grossSalary - (this.grossSalary * this.municipalitytax));

    this.arbetsgivarAvgift = Math.round(this.partialTotalAmount - this.grossSalary);
    this.displayTax = Math.round(this.grossSalary * this.municipalitytax);
    finalAmount = this.netSalary;
  }

  // expressSalaryCalculator
  myExpressCalculator(finalAmountExpress: number) {
    this.samFeeNew = Math.round(this.totalInvoiceAmountExpress * this.samFeeExpress);
    this.partialTotalAmount = Math.round(this.totalInvoiceAmountExpress - this.samFeeNew);

    this.grossSalary = Math.round(this.partialTotalAmount / (1 + this.tempEmployerTax));
    this.netSalaryExpress = Math.round(this.grossSalary - (this.grossSalary * this.municipalitytax));

    this.arbetsgivarAvgift = Math.round(this.partialTotalAmount - this.grossSalary);
    this.displayTax = Math.round(this.grossSalary * this.municipalitytax);

    finalAmountExpress = this.netSalaryExpress;
  }

  calculateServiceFeeAmount(totalInvoiceAmount: number): number {

    var _serviceFeeFactor = this.samFee;

    let tier1FeeFactor = _serviceFeeFactor;
    let tier2FeeFactor = 0.015;
    let tier3FeeFactor = 0.010;
    let tier4FeeFactor = 0.005;

    let tier1Fee = tier1FeeFactor;
    let tier2Fee = _serviceFeeFactor !== 0 ? Math.min(_serviceFeeFactor, tier2FeeFactor) : tier1FeeFactor;
    let tier3Fee = _serviceFeeFactor !== 0 ? Math.min(_serviceFeeFactor, tier3FeeFactor) : tier2FeeFactor;
    let tier4Fee = _serviceFeeFactor !== 0 ? Math.min(_serviceFeeFactor, tier4FeeFactor) : tier3FeeFactor;

    let amountInTier1 = Math.max(Math.min(totalInvoiceAmount, 100000), 0);
    let amountInTier2 = Math.max(Math.min(totalInvoiceAmount - 100000, 100000), 0);
    let amountInTier3 = Math.max(Math.min(totalInvoiceAmount - 200000, 300000), 0);
    let amountInTier4 = Math.max(totalInvoiceAmount - 500000, 0);

    let serviceFeeTier1 = amountInTier1 * tier1Fee;
    let serviceFeeTier2 = amountInTier2 * tier2Fee;
    let serviceFeeTier3 = amountInTier3 * tier3Fee;
    let serviceFeeTier4 = amountInTier4 * tier4Fee;

    let totalServiceFee = serviceFeeTier1 + serviceFeeTier2 + serviceFeeTier3 + serviceFeeTier4;
    debugger
    return parseFloat(totalServiceFee.toFixed(2));
  }

  // vatCalcStandard 25%
  myVatCalc(totalAmount: number) {
    this.withVat = Math.round(this.noVat * this.vatStandard);
    totalAmount = this.withVat;
  }

  myNoVatCalc(totalNoVat: number) {
    this.noVat = Math.round(this.withVat / this.vatStandard);
    totalNoVat = this.noVat;
  }

  // vatCalcMedium 12%
  myVatCalcMedium(totalAmountMedium) {
    this.withVatMedium = Math.round(this.noVatMedium * this.vatMedium);
    totalAmountMedium = this.withVatMedium;
  }

  myNoVatCalcMedium(totalNoVatMedium: number) {
    this.noVatMedium = Math.round(this.withVatMedium / this.vatMedium);
    totalNoVatMedium = this.noVatMedium;
  }

  // vatCalcMedium 6%
  myVatCalcLow(totalAmountLow) {
    this.withVatLow = Math.round(this.noVatLow * this.vatLow);
    totalAmountLow = this.withVatLow;
  }

  myNoVatCalcLow(totalNoVatLow: number) {
    this.noVatLow = Math.round(this.withVatLow / this.vatLow);
    totalNoVatLow = this.noVatLow;
  }

  loadMunicipalities() {
    this.municipalityService.getMunicipalities()
      .subscribe(value => this.municipalitiesLoaded(value),
        error => console.log(error)
      );
  }

  loadGlobalMessages() {
    this.globalmessageService.getGlobalMessages()
      .subscribe(value => this.globalMessagesLoaded(value),
        error => console.log(error)
      );
  }

  globalMessagesLoaded(globalmessages: any) {
    this.globalmessages = globalmessages
    console.log(this.globalmessages);
  }

  municipalitiesLoaded(municipalities: any) {
    this.municipalities = municipalities
  }

  municipalityChange(municipalityId: any) {

    for (var i = 0; i < this.municipalities.length; i++) {
      if (this.municipalities[i].Id == municipalityId) {
        this.user.AccountSettings.Municipality.Tax = this.municipalities[i].Tax;
      }
    }

  }

  openDialog(data: IGlobalMessage): void {
    const dialogRef = this.dialog.open(MessagesDialogComponent, {
      width: '500px;',
      data: { ...data },
    });

    localStorage.setItem('test', JSON.stringify(this.isRead == true));
    console.log(this.isRead);

    dialogRef.afterClosed().subscribe(result => {
      localStorage.getItem("test");
      console.log('The dialog was closed', this.isRead);
    });
  }

}
